import { plusBlueIcon } from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

export type ClassType = {
	section: string
	container: string
	secondaryColorBox: string
	box: string
	buttonOutline: string
	boxQuestion: string
	h2: string
	section1: string
	column1: string
	column2: string
	collapseListItem: string
	accordeonContainer: string
}

export const classes: ClassType = makeClasses({
	section: {
		margin: '50px',
		display: 'block',
		[Breakpoints.maxWidth('sm')]: {
			margin: '50px 20px'
		}
	},
	container: {
		display: 'grid',
		gridTemplateColumns: '2fr 1fr',
		gridAutoRows: 'minmax("fit-content", auto)',
		gridGap: '20px',
		[Breakpoints.maxWidth('xl')]: {
			gridTemplateColumns: 'repeat(1, 1fr)'
		}
	},
	column1: {
		[Breakpoints.maxWidth('xl')]: {
			gridRow: '2/2'
		}
	},
	column2: {
		[Breakpoints.maxWidth('xl')]: {
			gridRow: '1/2'
		}
	},
	secondaryColorBox: {
		fontWeight: 600,
		background: Colors.secondary,
		color: Colors.white,
		padding: '20px 30px'
	},
	box: {
		padding: 0,
		marginBottom: '30px !important',
		background: Colors.white,
		margin: '10px 0 10px',
		transition: '0.3s'
	},
	buttonOutline: {
		textDecoration: 'none',
		border: `1px solid ${Colors.white}`,
		color: `${Colors.white} !important`,
		background: 'transparent',
		padding: '15px 30px',
		fontSize: '16px',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		display: 'inline-block',
		fontWeight: 500,
		lineHeight: '1em',
		cursor: 'pointer',
		':hover': {
			borderColor: Colors.darkBlue2,
			background: Colors.darkBlue2
		}
	},
	boxQuestion: {
		padding: '40px 50px',
		fontSize: '22px',
		'& > p': {
			margin: '0 0 1rem 0'
		}
	},
	h2: {
		margin: '50px 0 15px',
		padding: 0,
		fontWeight: 500,
		fontSize: '28px',
		lineHeight: '31px',
		color: Colors.darkBlue1
	},
	section1: {
		background: Colors.white,
		padding: '30px'
	},
	collapseListItem: {
		marginTop: '30px',
		'& *': {
			background: Colors.white
		},
		'& .button': {
			padding: '20px 30px 20px 60px',
			color: Colors.lightBlack,
			fontSize: '18px',
			fontWeight: 400
		},
		'& .button:hover': {
			background: Colors.secondary,
			color: `${Colors.white} !important`,
			transition: '0.3s',
			'::after': {
				background: `transparent !important`
			},
			'::before': {
				backgroundColor: `${Colors.white} !important`,
				left: 'unset',
				top: 'unset',
				marginLeft: '-30px',
				marginTop: '10px',
				width: '14px',
				height: '2px'
			}
		},
		'& .button::after': {
			left: 'unset',
			top: 'unset',
			marginLeft: '-98.5px',
			marginTop: '10px',
			background: 'transparent',
			backgroundImage: 'none'
		},
		'& .button::before': {
			left: 'unset',
			top: 'unset',
			marginLeft: '-40px',
			marginTop: '-2px',
			background: `url(${plusBlueIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			width: '25px',
			height: '25px'
		},
		'& .ReactCollapse--content div': {
			padding: '8px 40px 5px 30px',
			margin: '0 0 15px',
			fontSize: '16px',
			lineHeight: '25px'
		},
		'& .active': {
			'& .button': {
				'::before': {
					backgroundColor: `${Colors.secondary}`,
					left: 'unset',
					top: 'unset',
					marginLeft: '-30px',
					marginTop: '10px',
					width: '14px',
					height: '2px'
				},
				':hover': {
					'::before': {
						backgroundColor: `${Colors.white}`,
						left: 'unset',
						top: 'unset',
						marginLeft: '-30px',
						marginTop: '10px',
						width: '14px',
						height: '2px'
					}
				}
			}
		}
	},
	accordeonContainer: {
		marginTop: '-20px',
		'ol, ul': {
			paddingLeft: '18px'
		},
		'ol li::marker': {
			fontWeight: 700,
			color: Colors.secondary
		},
		'ul li::marker': {
			color: Colors.secondary
		},
		'& a': {
			color: `${Colors.secondary} !important`,
			textDecoration: 'none',
			':hover': {
				textDecoration: 'underline'
			}
		}
	}
})
